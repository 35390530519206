import React from 'react'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { ComparisonCard } from './ComparisonCard'
import { useWarehouseDashboard } from 'features/dashboard/warehouseDashboardContext'
import { selectWarehouseById } from 'modules/warehouses'

const Container = styled.div(s('flex justify-between items-center', { width: 1300 }))

function CardSection({ currentYear, currentMonth, isLoading = false, ...otherProps }) {
  const { selectedWarehouseId } = useWarehouseDashboard()
  const selectedWarehouse = useSelector(selectWarehouseById(selectedWarehouseId))
  const warehouseName = selectedWarehouse?.name || 'All warehouses'

  return (
    <Container {...otherProps}>
      <ComparisonCard
        title="Total fund"
        description={warehouseName}
        isCurrentValuePercentage={false}
        isLoading={isLoading}
      />

      <ComparisonCard
        title="Provisioned fund"
        description={warehouseName}
        isCurrentValuePercentage={false}
        isLoading={isLoading}
      />

      <ComparisonCard
        title="Available fund"
        description={warehouseName}
        isCurrentValuePercentage={false}
        isLoading={isLoading}
      />

      <ComparisonCard
        title="Total loan"
        description={warehouseName}
        isCurrentValuePercentage={false}
        isLoading={isLoading}
      />
    </Container>
  )
}

export { CardSection }
