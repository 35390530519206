import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { s, styled } from '@vega/styled'
import { routes } from 'navigation/routes'
import {
  VerticalDotsIcon,
  WarehousesList,
  WarehouseConfiguration,
} from 'features/warehouseConfiguration'

import { navDrawerWidth } from 'styles'

const Root = styled.div(
  s('bg-green-50 h-full', {
    width: `calc(100% - ${navDrawerWidth}px)`,
  })
)

export const WarehousesConfigRouter = () => {
  const {
    warehousesConfigRouter: { root },
  } = routes

  const [isWarehouseListVisible, setIsWarehouseListVisible] = useState(false)

  const toggleWarehouseList = () => setIsWarehouseListVisible(!isWarehouseListVisible)

  return (
    <Root>
      {isWarehouseListVisible && <WarehousesList toggleList={toggleWarehouseList} />}
      <VerticalDotsIcon
        isActive={isWarehouseListVisible}
        onClick={toggleWarehouseList}
      />

      <Switch>
        <Route exact path={root} component={WarehouseConfiguration} />
      </Switch>
    </Root>
  )
}
