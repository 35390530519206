import { prop, propEq, isNilOrEmpty } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectWarehouseState = prop('warehouses')

export const selectInspectedWarehouseId = createSelector(
  selectWarehouseState,
  prop('inspectedWarehouseId')
)

export const selectWarehouses = createSelector(selectWarehouseState, prop('warehouses'))
export const selectWarehouseById = (warehouseId) =>
  createSelector(selectWarehouseState, ({ warehouses }) => {
    if (isNilOrEmpty(warehouseId)) return undefined

    return warehouses.find(propEq('id', warehouseId))
  })

export const {
  selectIsPending: selectIsFetchingWarehouses,
} = createAsyncThunkSelectors('warehouses/fetchWarehouses')
