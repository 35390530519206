import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('sticky bg-white border-1 border-solid border-grey-200 self-start p-2', {
    borderRadius: 6,
    width: 204,
    left: 0,
    top: 100,
  })
)

const Title = styled.h3(s('text-grey-800 text-sm font-semibold m-0 mb-3'))
const TableItem = styled.div(
  s('text-grey-700 text-sm py-3', {
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textUnderlineOffset: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  })
)

export const TableOfContent = ({ tableListData, ...otherProps }) => {
  const handleItemClicked = (label) => {
    const element = document.getElementById(label)

    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <Container {...otherProps}>
      <Title>On this page</Title>

      {tableListData.map((label) => (
        <TableItem key={label} onClick={() => handleItemClicked(label)}>
          {label}
        </TableItem>
      ))}
    </Container>
  )
}

const { string, arrayOf } = PropTypes

TableOfContent.propTypes = {
  tableListData: arrayOf(string),
}
