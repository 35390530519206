export const neoBank = {
  id: '615653baa37385622a38aeb9',
  name: 'Neo Bank',
  type: 'funding',
  criteria: [
    {
      name: 'LVR',
      attributes: [
        {
          name: '< 60',
          rawScore: 15,
          iterationOneRaw: 12,
          finalScore: 35,
        },
        {
          name: '≥ 60 and < 65',
          rawScore: 10,
          iterationOneRaw: 12,
          finalScore: 32,
        },
        {
          name: '≥ 65 and < 70',
          rawScore: 5,
          iterationOneRaw: 10,
          finalScore: 30,
        },
        {
          name: '≥ 70 and < 75',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: '# Inquiries 0-11 Months Excluding Last 30 Days',
      attributes: [
        {
          name: 'No Bureau Record/Hit',
          rawScore: -1,
          iterationOneRaw: -1,
          finalScore: 19,
        },
        {
          name: '0',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 25,
        },
        {
          name: '1',
          rawScore: 0,
          iterationOneRaw: 1,
          finalScore: 21,
        },
        {
          name: '2',
          rawScore: -5,
          iterationOneRaw: -2,
          finalScore: 18,
        },
        {
          name: '3 or more',
          rawScore: -10,
          iterationOneRaw: -10,
          finalScore: 10,
        },
        {
          name: 'No Investigation',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'I/O Status',
      attributes: [
        {
          name: 'P&I',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 25,
        },
        {
          name: 'I/O',
          rawScore: -1,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Borrower Type',
      attributes: [
        {
          name: 'Single',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'Joint',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 25,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Loan Purpose',
      attributes: [
        {
          name: 'O/O',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 25,
        },
        {
          name: 'Investment',
          rawScore: -1,
          iterationOneRaw: -1,
          finalScore: 19,
        },
        {
          name: 'Non-Housing',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 10,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Documentation',
      attributes: [
        {
          name: 'Full Documentation',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'Low Doc (LVR ≤ 60)',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: 'Low Doc (LVR > 60)',
          rawScore: -10,
          iterationOneRaw: -10,
          finalScore: 10,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Region (Based on Climate Risk)',
      attributes: [
        {
          name: 'At Least 1 Red',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'No Red at Least 1 Amber',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: 'Green, Green',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Refinance',
      attributes: [
        {
          name: 'No',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: 'External',
          rawScore: -10,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: 'Internal',
          rawScore: -5,
          iterationOneRaw: -2,
          finalScore: 18,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: 15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Loan to Income',
      attributes: [
        {
          name: '< 4x',
          rawScore: 5,
          iterationOneRaw: 10,
          finalScore: 30,
        },
        {
          name: '≥ 4x and < 5.5x',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
        {
          name: '≥ 5.5x and < 7x',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: '≥ 7',
          rawScore: -10,
          iterationOneRaw: -10,
          finalScore: 10,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Max Delq at the Credit Bureau',
      attributes: [
        {
          name: 'No Bureau Record/Hit',
          rawScore: -1,
          iterationOneRaw: -1,
          finalScore: 19,
        },
        {
          name: 'No Trade Lines',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: 'Current (Payment Not Required/Up-to-Date',
          rawScore: 15,
          iterationOneRaw: 15,
          finalScore: 35,
        },
        {
          name: '1 - 29 DPD',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 25,
        },
        {
          name: '30 - 59 DPD',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: 15,
        },
        {
          name: '60 - 89 DPD',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: 5,
        },
        {
          name: '90+ DPD, Hardship, Reaged',
          rawScore: -20,
          iterationOneRaw: -20,
          finalScore: 0,
        },
        {
          name: 'No Investigation/Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 20,
        },
      ],
    },
    {
      name: 'Property Type',
      attributes: [
        {
          name: 'Standalone Detatched Residences',
          rawScore: 10,
          iterationOneRaw: 10,
          finalScore: 10,
        },
        {
          name: 'Semidetatched or Deplexes, Row/Terrace Houses/Townhouses',
          rawScore: 5,
          iterationOneRaw: 5,
          finalScore: 5,
        },
        {
          name: 'Flats, Units, or Apartments',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 0,
        },
        {
          name: 'Vacant Residential Land',
          rawScore: -2,
          iterationOneRaw: -2,
          finalScore: -2,
        },
        {
          name: 'Rural Residential up to 10 ha',
          rawScore: -5,
          iterationOneRaw: -5,
          finalScore: -5,
        },
        {
          name: 'Rural Residential > 10 ha',
          rawScore: -10,
          iterationOneRaw: -10,
          finalScore: -10,
        },
        {
          name: 'Missing',
          rawScore: -15,
          iterationOneRaw: -15,
          finalScore: -15,
        },
        {
          name: 'Neutral',
          rawScore: 0,
          iterationOneRaw: 0,
          finalScore: 0,
        },
      ],
    },
  ],
}
