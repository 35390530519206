import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

const horizontalAlignment = s('flex justify-center')
const attributeColumnWidth = '50%'

const Container = styled.div(
  s('border-1 border-solid border-grey-200 rounded-lg bg-white pt-6', {
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);',
  })
)
const TopBar = styled.div(s('flex px-6'))
const Criteria = styled.div(s('text-grey-700 font-semibold'))

const Table = styled.div(s('flex flex-column'))
const TableHeaderRow = styled.div(
  s(
    'flex items-center justify-center border-0 border-b-1 border-solid border-grey-200 py-4'
  )
)
const TableHeader = styled.div(s('text-grey-700 text-xs', horizontalAlignment))

const TableBodyRow = styled.div(
  s(
    'flex items-center justify-center border-0 border-b-1 border-solid border-grey-200 py-4',
    {
      '&:last-child': {
        border: 0,
      },
    }
  )
)
const TableLeftHeader = styled.span(
  s('text-grey-900 font-semibold text-xs', horizontalAlignment)
)
const TableValue = styled.span(
  s('text-grey-900 font-semibold text-xs', horizontalAlignment)
)

const Column = styled.div(s('flex-1 flex justify-center'))

export const CreditScoreCard = ({ data, ...otherProps }) => {
  const { criteria, attributes, headers: tableHeaders } = data

  return (
    <Container {...otherProps}>
      <TopBar>
        <Criteria>{criteria}</Criteria>
      </TopBar>

      <Table>
        <TableHeaderRow>
          <div style={{ width: attributeColumnWidth }}>
            <TableHeader style={s('text-center')}>Attribute</TableHeader>
          </div>

          {tableHeaders.map((headerLabel) => (
            <Column key={headerLabel}>
              <TableHeader>{headerLabel}</TableHeader>
            </Column>
          ))}
        </TableHeaderRow>

        {attributes.map(({ name, data }) => {
          return (
            <TableBodyRow key={name}>
              <div style={{ width: attributeColumnWidth }}>
                <TableLeftHeader>{name}</TableLeftHeader>
              </div>
              {data.map((value, idx) => (
                <Column key={`${name}-${value}-${idx}`}>
                  <TableValue>{value}</TableValue>
                </Column>
              ))}
            </TableBodyRow>
          )
        })}
      </Table>
    </Container>
  )
}

const { string, number, arrayOf, shape } = PropTypes

CreditScoreCard.propTypes = {
  data: shape({
    criteria: string,
    headers: arrayOf(string),
    attributes: arrayOf(
      shape({
        name: string,
        data: arrayOf(number),
      })
    ),
  }),
}
