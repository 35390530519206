import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { TopBar } from 'features/dashboard/topBar'
import { s, styled } from '@vega/styled'
import { WarehouseDashboard } from 'features/dashboard/WarehouseDashboard'
import { WarehouseDashboardProvider } from 'features/dashboard/warehouseDashboardContext'
import { routes } from 'navigation/routes'

const Root = styled.div(s('flex flex-column bg-green-50 h-full w-full'))

export const DashboardRouter = () => {
  const {
    dashboard: { root },
  } = routes

  return (
    <WarehouseDashboardProvider>
      <Root>
        <TopBar />

        <Switch>
          <Route exact path={root} component={WarehouseDashboard} />
        </Switch>
      </Root>
    </WarehouseDashboardProvider>
  )
}
