import React from 'react'
import { equals, isNotNilOrEmpty } from '@solta/ramda-extra'
import { SOLTA_CONSTANTS } from '@neo/constants'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { styled, s } from '@vega/styled'
import { PasswordField } from '@vega/components'
import { TextField, OutlinedBtn, Row, CardContainer } from './common'

const { object, string } = Validator

const Sentence = styled.p(s('text-grey-700 m-0'))
const Collapsable = styled.div(({ shouldCollapse }) => ({
  transition: 'all 250ms ease-in-out',
  minHeight: 0,
  height: 0,
  paddingBottom: shouldCollapse ? 0 : 480,
  overflow: 'hidden',
}))

const Prompt = styled.span(s('text-grey-500 text-sm'))

const validationSchema = object({
  currentPassword: string().required('Current password is required'),

  newPassword: string()
    .required('New password is required')
    .matches(
      SOLTA_CONSTANTS.REGEX.MIN_PASSWORD_REQ_STRONG,
      'Password must contain at least 8 characters, one uppercase, one number'
    ),

  confirmNewPassword: string()
    .required('Confirm new password is required')
    .when(['newPassword'], {
      is: (newPassword) => isNotNilOrEmpty(newPassword),
      then: string().test(
        'is-confirm-new-password-matches-new-password',
        'Confirm new password is invalid',
        (confirmNewPassword, context) =>
          equals(confirmNewPassword, context.parent.newPassword)
      ),
    }),
})

const handleSubmit = ({ currentPassword, newPassword, confirmNewPassword }) => {
  return { currentPassword, newPassword, confirmNewPassword }
}

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}

export const UpdatePasswordCard = ({ ...otherProps }) => {
  const [isFormOpened, setIsFormOpened] = React.useState(false)

  return (
    <CardContainer {...otherProps}>
      <Sentence style={s('mb-8')}>Update a new password for your account</Sentence>
      <FormProvider
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm }) => (
          <Form>
            <Collapsable shouldCollapse={!isFormOpened}>
              <TextField
                name="currentPassword"
                label="Current password"
                inputBaseProps={{ type: 'password' }}
                style={s('mb-6')}
              />

              <div style={s('mb-6')}>
                <PasswordField
                  name="newPassword"
                  label="New password"
                  style={s('mb-2')}
                  inputContainerStyle={s('border-grey-300')}
                  inputProps={{
                    onPaste: (e) => e.preventDefault(),
                    onCopy: (e) => e.preventDefault(),
                  }}
                  fast={false}
                />
                <Prompt>Copy/paste is disabled</Prompt>
              </div>

              <TextField
                name="confirmNewPassword"
                label="Retype new password"
                inputBaseProps={{
                  type: 'password',
                  onPaste: (e) => e.preventDefault(),
                  onCopy: (e) => e.preventDefault(),
                }}
                style={s('mb-6')}
              />

              <Row style={s('justify-start')}>
                <OutlinedBtn style={s('mr-4')}>Change password</OutlinedBtn>

                <OutlinedBtn
                  type="button"
                  onClick={() => {
                    resetForm()
                    setIsFormOpened(false)
                  }}
                >
                  Cancel
                </OutlinedBtn>
              </Row>
            </Collapsable>

            {!isFormOpened && (
              <OutlinedBtn type="button" onClick={() => setIsFormOpened(true)}>
                Change password
              </OutlinedBtn>
            )}
          </Form>
        )}
      </FormProvider>
    </CardContainer>
  )
}
