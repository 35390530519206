/* eslint-disable react/no-multi-comp */
import React from 'react'
import { styled, s } from '@vega/styled'
import { TERRITORIAL_AUTHORITY } from '@neo/constants'
import { Map } from './Map'
import { LocationItem } from './LocationItem'
import { useMortgageMapData } from './useMortgageMapData'
import { DottedCircleLoader } from '@vega/components'

const Container = styled.div(
  s('flex flex-column bg-white rounded-lg border-1 border-solid border-grey-200 pt-5', {
    width: 750,
    height: 486,
    boxShadow:
      '0px 4px 8px rgba(34, 99, 94, 0.08), 1px 4px 24px rgba(34, 99, 94, 0.12)',
  })
)

const LocationListContainer = styled.div(
  s('', {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const Title = styled.h2(s('m-0 text-grey-800 text-xl font-semibold'))

const Row = styled.div(s('flex justify-between'))

const Loader = () => (
  <div style={s('w-full h-full flex justify-center items-center pb-24')}>
    <DottedCircleLoader size="lg" />
  </div>
)

const citiesAndDistricts = Object.keys(TERRITORIAL_AUTHORITY)
const descendingComparer = (a, b) => b.percentage - a.percentage

// eslint-disable-next-line react/no-multi-comp
export const MortgageMap = ({ mortgageCountByLocation = {}, isLoading }) => {
  const { data, minValue, maxValue } = useMortgageMapData(
    mortgageCountByLocation,
    citiesAndDistricts
  )

  return (
    <Container>
      <Title style={s('ml-6')}>Mortgage Map Location</Title>

      {isLoading ? (
        <Loader />
      ) : (
        <Row style={s('w-full px-10', { height: 420 })}>
          <Map
            data={data}
            minValue={minValue}
            maxValue={maxValue}
            containerStyle={s('h-full', { width: '60%' })}
            centerOffsetY={-120}
            centerOffsetX={20}
            scale={1100}
          />

          <LocationListContainer>
            {data.sort(descendingComparer).map((props) => (
              <LocationItem key={props.location} style={s('mb-4')} {...props} />
            ))}
          </LocationListContainer>
        </Row>
      )}
    </Container>
  )
}
