import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(s(''))
const Label = styled.div(
  s('mb-3 text-lg text-grey-600 font-normal', { lineHeight: 1.5 })
)
const Value = styled.div(
  s('text-4xl text-grey-800 font-semibold', {
    lineHeight: 1.22,
    letterSpacing: '0.02em',
  })
)

const FundField = ({ label, value, ...props }) => {
  return (
    <Container {...props}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  )
}

export { FundField }
