import React from 'react'
import { values } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'
import { CreditScoreCard } from './CreditScoreCard'

const Container = styled.div(s(''))

export const mapToCreditScoreCardData = ({ name, headers, attributes }) => {
  return {
    criteria: name,
    headers,
    attributes: attributes.map(({ name, ...otherProps }) => ({
      name,
      data: values(otherProps),
    })),
  }
}

function CreditScoreCardList({ criteriaData }) {
  const creditScoreCardsData = criteriaData.map(({ name, attributes }) =>
    mapToCreditScoreCardData({
      name,
      headers: ['Raw Score', 'Iteration 1 Raw', 'Final Score'],
      attributes,
    })
  )

  return (
    <Container>
      {creditScoreCardsData.map((data) => (
        <CreditScoreCard
          data={data}
          key={data.criteria}
          id={data.criteria}
          style={s('mb-6')}
        />
      ))}
    </Container>
  )
}

export { CreditScoreCardList }
