export const routes = {
  dashboard: {
    root: '/dashboard',
  },
  warehousesConfigRouter: {
    root: '/warehouses-config',
  },
  accountSettings: {
    root: '/account-settings',
  },
}
