import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { analyticsService } from 'apiService'
import { normalizeError } from '@vega/services'
import { mapToDashboardAnalytics } from './apiToViewModelMappers'

export const fetchDashboardAnalytics = createAsyncThunk(
  'analytics/fetchDashboardAnalytics',
  async ({ year, month, warehouseId }, { rejectWithValue }) => {
    try {
      return await analyticsService.fetchPortfolioDashboardAnalytics(year, month, [
        warehouseId,
      ])
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    dashboardAnalytics: {
      fund: { total: {}, remaining: {} },
      mortgage: {
        metrics: [],
        loan: {
          metricsByMonth: {},
        },
        customer: {
          type: {
            single: {
              total: 0,
              percentage: 0,
            },
            joint: {
              total: 0,
              percentage: 0,
            },
            others: {
              total: 0,
              percentage: 0,
            },
          },
        },
        property: {
          category: {
            apartment: {
              total: 0,
              percentage: 0,
            },
            house: {
              total: 0,
              percentage: 0,
            },
            others: {
              total: 0,
              percentage: 0,
            },
          },
          mortgageCountByLocation: {},
        },
      },
      dataPoint: {
        loanGranted: [],
        weightedAverageLvr: [],
        averageLoanSize: [],
      },
    },
  },
  extraReducers: {
    [fetchDashboardAnalytics.fulfilled]: (state, action) => {
      state.dashboardAnalytics = mapToDashboardAnalytics(action.payload)
    },
  },
})

const { reducer: analyticsReducer } = analyticsSlice

export { analyticsReducer }
