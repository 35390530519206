import React from 'react'
import { Link } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { ReactComponent as DashboardIcon } from '@vega/components/src/assets/images/barChartInDoc.svg'
import { ReactComponent as SettingIcon } from '@vega/components/src/assets/images/adjustment.svg'
import { ReactComponent as UserIcon } from '@vega/components/src/assets/images/user-circle.svg'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { NavItem } from './NavItem'
import { routes } from 'navigation/routes'
import { navDrawerWidth } from 'styles'

const Container = styled.div(
  s('flex flex-column items-center h-full shadow-md bg-white z-1 pt-4'),
  {
    minWidth: navDrawerWidth,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  }
)

const Img = styled.img(s('w-3'))
const ImageContainer = styled(Link)(
  s('w-full mt-8 mb-6 no-decoration flex justify-center self-center')
)
const HorizontalLine = styled.div(
  s('border-0 border-b-1 border-solid border-grey-200 w-3')
)

const NavigationDrawer = ({ homeRoute, children, ...otherProps }) => {
  const { dashboard, warehousesConfigRouter, accountSettings } = routes

  return (
    <Container {...otherProps}>
      <ImageContainer to={homeRoute}>
        <Img src={neoLogo} />
      </ImageContainer>

      <HorizontalLine />

      <NavItem
        style={s('mt-4')}
        route={dashboard.root}
        tooltipLabel="Dashboard"
        Icon={DashboardIcon}
      />

      <NavItem
        route={warehousesConfigRouter.root}
        tooltipLabel="Configuration"
        Icon={SettingIcon}
      />

      <NavItem
        route={accountSettings.root}
        tooltipLabel="Accounts"
        Icon={UserIcon}
        style={s('mt-a')}
      />
    </Container>
  )
}

export { NavigationDrawer }
