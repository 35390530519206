import React, { useRef, useState } from 'react'
import { styled, s } from '@vega/styled'
import { SearchBar } from '@vega/components'
import { FundField as BaseFundField } from './FundField'
import { CreditCriteria } from './CreditCriteria'
import { PoolParameter } from './PoolParameter'
import { ReactComponent as DeutscheBankLogo } from '@vega/components/src/assets/images/deutsche-bank-logo.svg'

const Container = styled.div(s('px-32 py-12'))
const HeaderContainer = styled.div(s('mb-8 flex justify-between'))
const Title = styled.div(
  s('text-3xl text-grey-800 font-semibold', {
    lineHeight: 1.21,
    letterSpacing: '0.02em',
  })
)
const LogoContainer = styled.div(s('mb-10 flex items-center'))
const WarehouseName = styled.div(
  s('ml-4 text-2xl text-grey-800 font-semibold', {
    lineHeight: 1.7,
    letterSpacing: '0.02em',
  })
)
const FundsContainer = styled.div(s('flex mb-16'))
const FundField = styled(BaseFundField)(s('mr-16'))
const TabsContainer = styled.div(s('relative flex self-end justify-start'))
const Tab = styled.div(
  s('text-grey-600 text-sm px-2 pb-4 border-0'),
  ({ isSelected }) => ({
    cursor: 'pointer',
    color: isSelected && s('text-green-700').color,
  })
)
const TabBottomLine = styled.div(
  s('absolute bg-green-600 pin-bl-0', {
    width: 106,
    height: 2,
    bottom: 0,
    transition: 'left 200ms ease-in-out, width 200ms ease-in-out',
  })
)
const DividingLine = styled.div(s('mb-8 w-full border-1 border-solid border-grey-200'))

const FundingWarehouse = () => {
  const tabBottomLineRef = useRef()
  const [selectedTab, setSelectedTab] = useState('creditCriteria')

  const onTabClicked = (event, label) => {
    const { offsetLeft, clientWidth } = event.target

    tabBottomLineRef.current.style.left = `${offsetLeft}px`
    tabBottomLineRef.current.style.width = `${clientWidth}px`

    setSelectedTab(label)
  }

  return (
    <Container>
      <HeaderContainer>
        <Title>Configurations</Title>
        <SearchBar placeholder="Search any criteria" containerStyle={s('bg-white')} />
      </HeaderContainer>

      <LogoContainer>
        <DeutscheBankLogo />
        <WarehouseName>Deutsche Bank</WarehouseName>
      </LogoContainer>

      <FundsContainer>
        <FundField label="Fund Size" value="$200M" />
        <FundField label="Used Fund" value="$20M" />
        <FundField label="Provisioned Funds" value="$20M" />
        <FundField label="Available Funds" value="$20M" style={s('mr-0')} />
      </FundsContainer>

      <TabsContainer>
        <Tab
          onClick={(e) => onTabClicked(e, 'creditCriteria')}
          isSelected={selectedTab === 'creditCriteria'}
        >
          Credit Criteria
        </Tab>

        <Tab
          onClick={(e) => onTabClicked(e, 'poolParameter')}
          isSelected={selectedTab === 'poolParameter'}
        >
          Pool Parameter
        </Tab>
        <TabBottomLine ref={tabBottomLineRef} />
      </TabsContainer>

      <DividingLine />

      {selectedTab === 'creditCriteria' ? <CreditCriteria /> : <PoolParameter />}
    </Container>
  )
}

export { FundingWarehouse }
