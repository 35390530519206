import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('flex flex-column items-start pt-16'))
const ColorLegendItem = styled.div(s('flex items-center justify-between mb-4'))
const ColorDot = styled.div(s('rounded-full mr-2'), ({ color }) => ({
  backgroundColor: color,
  width: 12,
  height: 12,
}))
const ColorLegendLabel = styled.span(s('text-grey-600'))

function ColorLegend({ data, isLoading, ...otherProps }) {
  if (isLoading) return null

  return (
    <Container {...otherProps}>
      {data.map(({ dotColor, label, key }) => (
        <ColorLegendItem key={key}>
          <ColorDot color={dotColor} />
          <ColorLegendLabel>{label}</ColorLegendLabel>
        </ColorLegendItem>
      ))}
    </Container>
  )
}

export { ColorLegend }
