import React from 'react'
import { styled, keyframes, s } from '@vega/styled'

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const Container = styled.div(s(''))
const Row = styled.div(s('flex justify-between'))

const Location = styled.span(s('text-grey-800 text-xs font-semibold'))
const Percentage = styled.span(s('text-grey-400 text-xs'))

const PercentageBar = styled.div(
  s('relative w-full rounded-sm'),
  ({ filledPercentage }) => ({
    height: 10,
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',

    // Filled percentage bar
    '&::before': s('absolute h-full rounded-sm bg-green-500', {
      content: '""',
      transform: 'translateX(-100%)',
      animation: `800ms ${slideInFromLeft} cubic-bezier(0.66, 0, 0.2, 0.94) forwards`,
      width: `${filledPercentage}%`,
    }),
  })
)

export const LocationItem = ({ location = 'AKL', percentage = 57, ...otherProps }) => {
  return (
    <Container {...otherProps}>
      <Row style={s('mb-2')}>
        <Location>{location}</Location>
        <Percentage>{percentage}%</Percentage>
      </Row>

      <PercentageBar filledPercentage={percentage} />
    </Container>
  )
}
