import React from 'react'

import { styled, s } from '@vega/styled'
import { APP_URLS, APP_SERVICES } from '@neo/constants'
import { USER_ROLES } from '@vega/constants'

import { ReactComponent as Cross } from '../assets/images/x.svg'
import { ReactComponent as Coin } from '../assets/images/coin.svg'
import { ReactComponent as Folder } from '../assets/images/folder.svg'
import { ReactComponent as Pinches } from '../assets/images/pinches.svg'
import { ReactComponent as CheckMark } from '../assets/images/checkmark.svg'
import { ReactComponent as BankingAdmin } from '../assets/images/banking-admin.svg'
import { ReactComponent as CreditCard } from '../assets/images/creditCard.svg'
import { ReactComponent as PortfolioManagement } from '../assets/images/portfolio-management.svg'

const Container = styled.div(
  s('absolute pin-r-0 pin-t-0 h-full bg-white z-2 px-8', {
    border: '1px solid #F4F4F5',
    width: 344,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  })
)

const TopContainer = styled.div(s('flex items-center justify-between my-8'))
const Title = styled.div(
  s('text-xl text-left font-medium', { fontFamily: 'Gill Sans' })
)
const CloseButton = styled.div(s('', { cursor: 'pointer' }))
const Services = styled.div(s('flex flex-wrap justify-between', { overflowY: 'auto' }))
const IconContainer = styled.div(s('rounded-lg flex items-center justify-center'), {
  height: 100,
})

const ServiceLink = styled.div(
  s('m-2 rounded-lg flex flex-column flex-wrap-none', {
    width: 122,
    cursor: 'pointer',
    '&:hover': s('bg-grey-lightest'),
    '&:only-of-type': s('w-full'),
  }),

  ({ url }) =>
    window.location.href.includes(url) && s('border-solid border-2 border-green-700')
)
const ServiceTitle = styled.div(
  s('text-sm text-center pt-1 pb-3', {
    fontFamily: 'Gill Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)

const {
  VEGA_LEND_ADMIN,
  VEGA_LEND_BROKER,
  VEGA_LEND_CLIENT,
  VEGA_BANKING_ADMIN,
  VEGA_BANKING_CUSTOMER,
  VEGA_PORTFOLIO_MANAGEMENT,
  VEGA_LOAN_ORIGINATION,
} = APP_SERVICES

const {
  LENDING_ADMIN,
  BROKER,
  CLIENT,
  BANKING_ADMIN,
  BANKING_CLIENT,
  BANKING_PORTFOLIO_ADMIN,
  LEAD_ASSESSOR,
} = USER_ROLES

const services = [
  {
    key: VEGA_LEND_ADMIN,
    title: 'Neo Lend Admin',
    url: `${APP_URLS.LENDING}admin`,
    Icon: Coin,
    role: LENDING_ADMIN,
  },
  {
    key: VEGA_LEND_BROKER,
    title: 'Neo Lend Broker',
    url: `${APP_URLS.LENDING}broker`,
    Icon: Pinches,
    role: BROKER,
  },
  {
    key: VEGA_LOAN_ORIGINATION,
    title: 'Neo Loan Origination',
    url: APP_URLS.LOAN_ORIGINATION,
    Icon: CheckMark,
    role: LEAD_ASSESSOR,
  },
  {
    key: VEGA_LEND_CLIENT,
    title: 'Neo Lend Client',
    url: `${APP_URLS.LENDING}client`,
    Icon: Folder,
    role: CLIENT,
  },
  {
    key: VEGA_BANKING_ADMIN,
    title: 'Banking Admin',
    url: `${APP_URLS.BANKING}admin`,
    Icon: BankingAdmin,
    role: BANKING_ADMIN,
  },
  {
    key: VEGA_BANKING_CUSTOMER,
    title: 'Banking Customer',
    url: `${APP_URLS.BANKING}customer`,
    Icon: CreditCard,
    role: BANKING_CLIENT,
  },
  {
    key: VEGA_PORTFOLIO_MANAGEMENT,
    title: 'Portfolio Management',
    url: APP_URLS.BANKING_PORTFOLIO,
    Icon: PortfolioManagement,
    role: BANKING_PORTFOLIO_ADMIN,
  },
]

const getValidServices = (authorisedApps) => {
  return services.filter((svc) => authorisedApps.includes(svc.key))
}

const ServicesPanel = ({ isPanelOpen, onClose, authorisedApps }) => {
  const validServices = getValidServices(authorisedApps)

  const switchApp = (role, url) => {
    window.localStorage.setItem('vegaUserCurrentRole', role)

    window.location.assign(url)
  }

  if (!isPanelOpen) {
    return null
  }

  return (
    <Container>
      <TopContainer>
        <Title>Other workspaces</Title>
        <CloseButton onClick={onClose}>
          <Cross height={24} width={24} />
        </CloseButton>
      </TopContainer>

      <Services>
        {validServices.map(({ title, url, Icon, role }) => (
          <ServiceLink
            onClick={() => switchApp(role, url)}
            key={title}
            url={url}
            title={title}
          >
            <IconContainer>
              <Icon height={81} width={98} />
            </IconContainer>
            <ServiceTitle>{title}</ServiceTitle>
          </ServiceLink>
        ))}
      </Services>
    </Container>
  )
}

export { ServicesPanel }
