import React from 'react'
import { useSelector } from 'react-redux'
import { AppSwitcher as BaseAppSwitcher } from '@vega/components'
import { selectProfileRoles } from '@vega/redux.profile'

const AppSwitcher = () => {
  const roles = useSelector(selectProfileRoles)

  return <BaseAppSwitcher usersRoles={roles} />
}

export { AppSwitcher }
