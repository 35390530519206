import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { warehouseService } from 'apiService'

export const fetchWarehouses = createAsyncThunk(
  'warehouses/fetchWarehouses',
  async (_, { rejectWithValue }) => {
    try {
      const warehouses = await warehouseService.fetchWarehouses()

      return warehouses
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

const warehouseSlice = createSlice({
  name: 'warehouses',
  initialState: {
    warehouses: [],
    inspectedWarehouseId: '',
  },
  reducers: {
    setInspectedWarehouseId: (state, action) => {
      const id = action.payload
      state.inspectedWarehouseId = id
    },
  },
  extraReducers: {
    [fetchWarehouses.fulfilled]: (state, action) => {
      const { items: warehouses } = action.payload

      state.warehouses = warehouses
    },
  },
})

export const { setInspectedWarehouseId } = warehouseSlice.actions

const { reducer: warehouseReducer } = warehouseSlice

export { warehouseReducer }
