import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('bg-white border-1 border-solid border-grey-200 rounded-lg px-5 py-4')
)
const Heading = styled.div(
  s('text-base font-semibold text-grey-800', { lineHeight: 1.5 })
)
const CriteriaList = styled.ul({ listStylePosition: 'outside' })
const CriteriaItem = styled.li(
  s('text-base font-normal text-grey-800', { lineHeight: 1.5 })
)

const CreditCriteria = () => {
  return (
    <Container>
      <Heading>Key criteria including but not limited to:</Heading>

      <CriteriaList>
        <CriteriaItem>Maximum loan size</CriteriaItem>

        <CriteriaList>
          <CriteriaItem>NZ$1m in non‐metro areas</CriteriaItem>
          <CriteriaItem>NZ$2m in metro areas</CriteriaItem>
        </CriteriaList>

        <CriteriaItem>Maximum borrower exposure NZ$3m</CriteriaItem>
        <CriteriaItem>Maximum LVR 75%</CriteriaItem>
        <CriteriaItem>
          Maximum term (including any interest only period) of 30 years
        </CriteriaItem>
        <CriteriaItem>Maximum interest only (IO) period of 5 years</CriteriaItem>
        <CriteriaItem>Maximum fixed rate period of 5 years</CriteriaItem>
        <CriteriaItem>Maximum number of properties for an investor of 5</CriteriaItem>
        <CriteriaItem>
          Prime loans, i.e. no credit impaired loans (impairment levels to be agreed)
        </CriteriaItem>
        <CriteriaItem>
          Full documentation loans only, i.e. no &apos;alternate verification,&apos;
          &apos;low doc,&apos; or &apos;no doc&apos; loans
        </CriteriaItem>
        <CriteriaItem>Full valuations only</CriteriaItem>
        <CriteriaItem>No hardship or restructured loans</CriteriaItem>
        <CriteriaItem>No loans reallocated from other trusts</CriteriaItem>
        <CriteriaItem>
          No construction loans or partially completed dwellings
        </CriteriaItem>
        <CriteriaItem>No vacant land</CriteriaItem>
        <CriteriaItem>No line of credit Loans</CriteriaItem>
        <CriteriaItem>No developer residual stock</CriteriaItem>
        <CriteriaItem>No debt consolidation loans</CriteriaItem>
        <CriteriaItem>
          No rural residential loans (i.e. loans &gt; 5 acres)
        </CriteriaItem>
        <CriteriaItem>
          Minimum apartment or unit size 50sqm, and no serviced apartments
        </CriteriaItem>
        <CriteriaItem>No bridging loans</CriteriaItem>
        <CriteriaItem>No commercial loans</CriteriaItem>
        <CriteriaItem>No second mortgage loans</CriteriaItem>
        <CriteriaItem>
          There must be either (a) a population centre of greater than 10,000 people
          within ten kilometres of the mortgaged property, or (b) a population centre of
          greater than 5,000 people within ten kilometres of the mortgaged property if
          within 2.5 kilometres of the coast
        </CriteriaItem>
        <CriteriaItem>The loan is an SPPI receivable</CriteriaItem>
        <CriteriaItem>Minimum serviceability requirements (to be agreed)</CriteriaItem>
        <CriteriaItem>Acceptable income types (to be agreed)</CriteriaItem>
        <CriteriaItem>Maximum cash out of $500k</CriteriaItem>
        <CriteriaItem>
          No bullet loans, i.e. the loan must amortise over the term of the loan
        </CriteriaItem>
        <CriteriaItem>No SMSF loans</CriteriaItem>
        <CriteriaItem>No first home buyer loans</CriteriaItem>
        <CriteriaItem>No expatriates</CriteriaItem>
      </CriteriaList>
    </Container>
  )
}

export { CreditCriteria }
