import React from 'react'
import { defaultTo, propEq, isNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'
import { BarChart } from './BarChart'
import { DottedCircleLoader } from '@vega/components'

const Container = styled.div(
  s('px-6 py-8 bg-white border-1 border-solid border-grey-200 rounded-lg', {
    width: 531,
    height: 486,
    boxShadow:
      '0px 4px 8px rgba(34, 99, 94, 0.08), 1px 4px 24px rgba(34, 99, 94, 0.12)',
  })
)
const Title = styled.p(
  s('m-0 mb-6 text-xl text-grey-800 font-semibold', {
    lineHeight: 1.2,
    letterSpacing: '0.02em',
  })
)

/**
 * Early return 0 if passed value is nil or empty to avoid run time error in calculation
 * @param {number} value
 */
const safelyMinusBy100 = (value) => {
  if (isNilOrEmpty(value)) return 0

  return 100 - value
}

const Loader = () => (
  <div style={s('w-full h-full flex justify-center items-center pb-24')}>
    <DottedCircleLoader size="lg" />
  </div>
)

// eslint-disable-next-line react/no-multi-comp
const LoanDetails = ({ mortgageMetrics = [], isLoading = false, ...props }) => {
  const investmentLoanMetric = mortgageMetrics.find(propEq('name', 'investment loans'))
  const investmentLoansAndLvrMetric = mortgageMetrics.find(
    propEq('name', 'investment loans and LVR > 70%')
  )
  const interestOnlyMetric = mortgageMetrics.find(
    propEq('name', 'interest rate only loans')
  )
  const interestOnlyAndLvrMetric = mortgageMetrics.find(
    propEq('name', 'interest only loans and LVR > 70%')
  )
  const fixedRateMetric = mortgageMetrics.find(propEq('name', 'fixed rate loans'))

  return (
    <Container {...props}>
      <Title>Loan Details</Title>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BarChart
            title="Investment Loans"
            leftSideData={{
              name: 'Investment loans <60%',
              value: defaultTo(0, investmentLoanMetric?.percentage),
            }}
            rightSideData={{
              name: 'Investment loans >=60%',
              value: safelyMinusBy100(investmentLoanMetric?.percentage),
            }}
          />

          <BarChart
            title="Investment Loans and LVR"
            leftSideData={{
              name: 'Investment Loans and LVR > 70%',
              value: defaultTo(0, investmentLoansAndLvrMetric?.percentage),
            }}
            rightSideData={{
              name: 'Investment Loans and LVR < 70%',
              value: safelyMinusBy100(investmentLoansAndLvrMetric?.percentage),
            }}
          />

          <BarChart
            title="Interest rate"
            leftSideData={{
              name: 'Interest rate only',
              value: defaultTo(0, interestOnlyMetric?.percentage),
            }}
            rightSideData={{
              name: 'Normal',
              value: safelyMinusBy100(interestOnlyMetric?.percentage),
            }}
          />

          <BarChart
            title="Interest rate and LVR"
            leftSideData={{
              name: 'Interest rate only and LVR > 70%',
              value: defaultTo(0, interestOnlyAndLvrMetric?.percentage),
            }}
            rightSideData={{
              name: 'Interest rate only and LVR < 70%',
              value: safelyMinusBy100(interestOnlyAndLvrMetric?.percentage),
            }}
          />

          <BarChart
            title="Fixed rate loans"
            leftSideData={{
              name: 'Fixed rate < 75%',
              value: defaultTo(0, fixedRateMetric?.percentage),
            }}
            rightSideData={{
              name: 'Fixed rate > 75%',
              value: safelyMinusBy100(fixedRateMetric?.percentage),
            }}
          />
        </>
      )}
    </Container>
  )
}

export { LoanDetails }
