import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as DotsVertical } from '@vega/components/src/assets/images/dots-vertical.svg'

const Container = styled.div(
  s('fixed px-2 py-2 flex justify-center items-center bg-green-100 rounded-full z-1', {
    width: 44,
    height: 44,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
    top: 30,
    left: 80,
    cursor: 'pointer',
  }),
  ({ isActive }) => isActive && { left: 283 }
)

const VerticalDotsIcon = ({ isActive, ...props }) => (
  <Container isActive={isActive} {...props}>
    <DotsVertical style={s('text-grey-700')} />
  </Container>
)

export { VerticalDotsIcon }
