import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { setInspectedWarehouseId, selectInspectedWarehouseId } from 'modules/warehouses'
import { WarehouseListItem } from './WarehouseListItem'

const Container = styled.div(
  s('absolute py-12 bg-white h-full z-1', {
    minWidth: 235,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  })
)
const Title = styled.div(
  s('px-5 pb-8 text-xl text-grey-800 font-bold', {
    lineHeight: 1.2,
    letterSpacing: '0.02em',
  })
)

// TODO: replace with api call to retrieve all warehouses
const warehouses = [
  { id: '', name: 'Neo Bank', type: 'default' },
  { id: '615653b050068de5d3fae0fe', name: 'Deutsche Bank', type: 'funding' },
]

const WarehousesList = ({ toggleList }) => {
  const dispatch = useDispatch()

  const inspectedWarehouseId = useSelector(selectInspectedWarehouseId)

  const setWarehouseId = (id) => {
    dispatch(setInspectedWarehouseId(id))
    toggleList()
  }

  return (
    <Container>
      <Title>All Warehouses</Title>

      {warehouses.map(({ id, name }) => (
        <WarehouseListItem
          key={id}
          isActive={inspectedWarehouseId === id}
          name={name}
          onClick={() => setWarehouseId(id)}
        />
      ))}
    </Container>
  )
}

export { WarehousesList }
