import React from 'react'
import { LineChartBase } from './LineChartBase'

export const EmptyLineChart = ({
  height,
  approxHighestValueForEmptyChart,
  ...otherProps
}) => {
  const emptyChartData = [
    {
      data: [
        {
          x: new Date(),
          y: approxHighestValueForEmptyChart,
        },
      ],
      lineStyle: {
        color: 'transparent',
      },
      key: 'empty chart',
    },
  ]

  return (
    <LineChartBase
      {...otherProps}
      height={height}
      showVerticalCrosshair={false}
      showTooltip={false}
      showXAxis={false}
      lineChartData={emptyChartData}
    />
  )
}
