import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'
import { Bar } from './Bar'

const Container = styled.div(s('mb-6'))
const TopContainer = styled.div(s('mb-1 flex justify-between'))
const BottomContainer = styled.div(s('flex justify-between'))
const Title = styled.p(s('m-0 text-sm text-grey-700', { lineHeight: 1.5 }))
const Value = styled.p(s('m-0 text-sm text-grey-500', { lineHeight: 1.5 }))
const Name = styled.p(
  s('m-0 font-semibold', { fontSize: 10, lineHeight: 1.4, letterSpacing: '0.02em' })
)

const BarChart = ({ title, leftSideData, rightSideData, ...props }) => {
  const { name: leftName, value: leftValue } = leftSideData
  const { name: rightName, value: rightValue } = rightSideData

  return (
    <Container {...props}>
      <TopContainer>
        <Value>{leftValue}%</Value>
        <Title>{title}</Title>
        <Value>{rightValue}%</Value>
      </TopContainer>

      <Bar value={leftValue} />

      <BottomContainer>
        <Name style={s('text-green-600')}>{leftName}</Name>
        <Name style={s('text-grey-700')}>{rightName}</Name>
      </BottomContainer>
    </Container>
  )
}

const { number, string, shape } = PropTypes

BarChart.propTypes = {
  title: string,
  leftSideData: shape({
    name: string,
    value: number,
  }),
  rightSideData: shape({
    name: string,
    value: number,
  }),
}

export { BarChart }
