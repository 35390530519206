/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ErrorPage, Loading } from '@vega/components'
import { APPS } from '@neo/constants'
import { USER_ROLES } from '@vega/constants'
import {
  fetchMyProfile,
  selectIsFetchMyProfileLoading,
  selectIsSessionValid,
  selectProfileRoles,
} from '@vega/redux.profile'
import { redirectUnauthenticatedUserToLogIn } from '@neo/app-access-controller'
import { ProtectedRouter } from './ProtectedRouter'
import { styled, s } from '@vega/styled'

const { SUPER_ADMIN, BANKING_PORTFOLIO_ADMIN } = USER_ROLES

const Root = styled.div(s('flex h-full'))

const isDev = process.env.NODE_ENV === 'development'

/**
 * Get the single role that user is representing now
 * as user can have multiple roles attached in the account
 * @param {string[]} userRoles
 * @example
 * ["vg_super_admin", "vg_banking_client", "vg_banking_portfolio_admin"]
 * @returns {string | undefined}
 */
const getUserCurrentRole = (userRoles = []) => {
  if (userRoles.find((r) => r === SUPER_ADMIN)) return SUPER_ADMIN
  if (userRoles.find((r) => r === BANKING_PORTFOLIO_ADMIN))
    return BANKING_PORTFOLIO_ADMIN

  return undefined
}

export const RootRouter = () => {
  const dispatch = useDispatch()

  const isFetchingMyProfile = useSelector(selectIsFetchMyProfileLoading)
  const isSessionValid = useSelector(selectIsSessionValid)
  const roles = useSelector(selectProfileRoles) ?? []
  const userCurrentRole = getUserCurrentRole(roles)

  useEffect(() => {
    Cookies.set('appInfo', JSON.stringify({ name: APPS.BANKING_PORTFOLIO }), {
      domain: process.env.REACT_APP_VEGA_SUBDOMAIN,
      secure: !isDev,
      sameSite: 'strict',
    })
  }, [])

  useEffect(() => {
    dispatch(fetchMyProfile())
  }, [dispatch])

  // This is to update the user's current role each time they switch apps.
  // This is needed here as each app is nested in this router
  useEffect(() => {
    if (isNotNilOrEmpty(userCurrentRole))
      window.localStorage.setItem('vegaUserCurrentRole', userCurrentRole)
  }, [userCurrentRole])

  if (isFetchingMyProfile) return <Loading />

  const isLoggedInUser = !isFetchingMyProfile && isSessionValid
  if (!isLoggedInUser) redirectUnauthenticatedUserToLogIn(APPS.BANKING_PORTFOLIO)

  const userRoleHasAccessRight = isNotNilOrEmpty(userCurrentRole)
  if (!userRoleHasAccessRight) return <ErrorPage />

  return (
    <Root>
      <Switch>
        <ProtectedRouter />

        <Route path="/" component={ErrorPage} />
      </Switch>
    </Root>
  )
}
