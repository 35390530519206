import React from 'react'

export const useFluidTabs = (listOfTabs = [], bottomLineRef) => {
  const [selectedTab, setSelectedTab] = React.useState(listOfTabs[0])

  const handleTabBottomLineMove = React.useCallback(
    (event, toTab) => {
      const { offsetLeft, clientWidth } = event.target

      bottomLineRef.current.style.left = `${offsetLeft}px`
      bottomLineRef.current.style.width = `${clientWidth}px`

      setSelectedTab(toTab)
    },
    [bottomLineRef]
  )

  return { handleTabBottomLineMove, selectedTab, setSelectedTab }
}
