import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('px-5 py-3 text-base text-grey-800 font-semibold', {
    lineHeight: 1.5,
    cursor: 'pointer',
  }),
  ({ isActive }) => isActive && s('bg-green-100')
)

const WarehouseListItem = ({ isActive = false, name, ...props }) => (
  <Container isActive={isActive} {...props}>
    {name}
  </Container>
)

export { WarehouseListItem }
