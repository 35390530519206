import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('bg-white border-1 border-solid border-grey-200 rounded-lg px-5 py-4')
)
const Heading = styled.div(
  s('text-base font-semibold text-grey-800', { lineHeight: 1.5 })
)
const ParameterList = styled.ul({ listStylePosition: 'outside' })
const ParameterItem = styled.li(
  s('text-base font-normal text-grey-800', { lineHeight: 1.5 })
)

const PoolParameter = () => (
  <Container>
    <Heading>Key parameters including but not limited to:</Heading>

    <ParameterList>
      <ParameterItem>Average loan size ≤ NZ$650k</ParameterItem>
      <ParameterItem>Loans &gt; NZ$1m ≤ 20%</ParameterItem>
      <ParameterItem>Loans &gt; NZ$1.5m ≤ 10%</ParameterItem>
      <ParameterItem>LVR &gt; 70% ≤ 50%</ParameterItem>
      <ParameterItem>WA LVR of 70%</ParameterItem>
      <ParameterItem>Investment loans ≤ 60%</ParameterItem>
      <ParameterItem>Investment loans and LVR &gt; 70% ≤ 20%</ParameterItem>
      <ParameterItem>Interest only loans ≤ 40%</ParameterItem>
      <ParameterItem>Interest only loans and LVR &gt; 70% ≤ 20%</ParameterItem>
      <ParameterItem>Fixed rate loans ≤ 75% (DB to be the swap provider)</ParameterItem>
      <ParameterItem>Self‐employed loans ≤ 20%</ParameterItem>
      <ParameterItem>Auckland ≤ 70%</ParameterItem>
      <ParameterItem>South Island ≤ 25%</ParameterItem>
      <ParameterItem>Non‐metro ≤ 20%</ParameterItem>
      <ParameterItem>Apartments ≤ 20%</ParameterItem>
      <ParameterItem>Single obligor ≤ 5%</ParameterItem>
      <ParameterItem>Single postcode ≤ 10%</ParameterItem>
    </ParameterList>
  </Container>
)

export { PoolParameter }
