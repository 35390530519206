import React from 'react'
import { map } from '@solta/ramda-extra'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { format } from 'date-fns'
import { MonthPicker } from '@vega/components'
import { useWarehouseDashboard } from 'features/dashboard/warehouseDashboardContext'
import { selectWarehouses } from 'modules/warehouses'

const Container = styled.div(
  s(
    'flex justify-between items-center bg-white border-0 border-b-1 border-solid border-grey-200 px-8',
    {
      minHeight: 100,
    }
  )
)
const TabsContainer = styled.div(s('relative flex self-end justify-start'))
const Tab = styled.div(
  s('text-grey-500 text-sm px-2 pb-4 border-0'),
  ({ isSelected }) => ({
    cursor: 'pointer',
    color: isSelected && s('text-green-700').color,
  })
)
const TabBottomLine = styled.div(
  s('absolute bg-green-600 pin-bl-0', {
    width: 75,
    height: 2,
    bottom: 0,
    transition: 'left 200ms ease-in-out, width 200ms ease-in-out',
  })
)

const LastUpdatedText = styled.p(s('text-grey-600 font-medium text-xs m-0 mb-1'))

const OperationContainer = styled.div(s('flex items-center justify-end'))

export const mapWarehousesDataToTabsData = (warehouses) =>
  map(({ id, name }) => ({ warehouseId: id, label: name }), warehouses)

export const TopBar = () => {
  const {
    setSelectedWarehouseId,
    setSelectedYear,
    setSelectedMonth,
  } = useWarehouseDashboard()
  const tabBottomLineRef = React.useRef()
  const [selectedTab, setSelectedTab] = React.useState('overview')

  const onMonthPickerMonthChanged = ({ year, month }) => {
    setSelectedYear(year)
    setSelectedMonth(month)
  }

  const warehouses = useSelector(selectWarehouses)
  const tabsData = mapWarehousesDataToTabsData(warehouses)

  const onTabClicked = (event, warehouseId, tabLabel) => {
    setSelectedWarehouseId(warehouseId)

    const { offsetLeft, clientWidth } = event.target

    tabBottomLineRef.current.style.left = `${offsetLeft}px`
    tabBottomLineRef.current.style.width = `${clientWidth}px`

    setSelectedTab(tabLabel)
  }

  return (
    <Container>
      <TabsContainer>
        <Tab
          onClick={(e) => onTabClicked(e, '', 'overview')}
          isSelected={selectedTab === 'overview'}
        >
          Overview
        </Tab>

        {tabsData.map(({ warehouseId, label }) => (
          <Tab
            key={label}
            onClick={(e) => onTabClicked(e, warehouseId, label)}
            isSelected={selectedTab === label}
          >
            {label}
          </Tab>
        ))}
        <TabBottomLine ref={tabBottomLineRef} />
      </TabsContainer>

      <div>
        <LastUpdatedText>
          Last updated on {format(new Date(), 'd MMM yyyy')} at{' '}
          {format(new Date(), 'p')}
        </LastUpdatedText>
        <OperationContainer>
          <MonthPicker
            onMonthChanged={onMonthPickerMonthChanged}
            pickerStyle={{ left: 'initial', right: 50, top: 90 }}
          />
        </OperationContainer>
      </div>
    </Container>
  )
}
