export const mapToDashboardAnalytics = (payload) => {
  const mortgage = payload?.mortgage

  return {
    mortgage: {
      metrics: mortgage?.metrics,
      loan: {
        metricsByMonth: mortgage?.grantedLoan?.metricsByMonth,
      },
      customer: {
        type: {
          single: {
            total: mortgage?.customer?.type?.single?.total,
            percentage: mortgage?.customer?.type?.single?.percentage,
          },
          joint: {
            total: mortgage?.customer?.type?.joint?.total,
            percentage: mortgage?.customer?.type?.joint?.percentage,
          },
          others: {
            total: mortgage?.customer?.type?.others?.total,
            percentage: mortgage?.customer?.type?.others?.percentage,
          },
        },
      },
      property: {
        category: {
          apartment: {
            total: mortgage?.property?.category?.apartment?.total,
            percentage: mortgage?.property?.category?.apartment?.percentage,
          },
          house: {
            total: mortgage?.property?.category?.house?.total,
            percentage: mortgage?.property?.category?.house?.percentage,
          },
          others: {
            total: mortgage?.property?.category?.others?.total,
            percentage: mortgage?.property?.category?.others?.percentage,
          },
        },
        mortgageCountByLocation: mortgage?.property?.mortgageCountByLocation,
      },
    },
    dataPoint: {
      loanGranted: mortgage?.grantedLoan?.totalAmount?.dataPoints,
      weightedAverageLvr: mortgage?.lvr?.weightedAverage?.dataPoints,
      averageLoanSize: mortgage?.grantedLoan?.averageAmount?.dataPoints,
    },
  }
}
