import { configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { profileReducer } from '@vega/redux.profile'
import { warehouseReducer } from 'modules/warehouses'
import { analyticsReducer } from 'modules/analytics'

const store = configureStore({
  reducer: {
    asyncState: asyncStateReducer,
    profile: profileReducer,
    warehouses: warehouseReducer,
    analytics: analyticsReducer,
  },
})

export default store
