import React from 'react'

const context = React.createContext({})
const { Provider } = context

export function useWarehouseDashboard() {
  return React.useContext(context)
}

export const WarehouseDashboardProvider = ({ children }) => {
  const [selectedWarehouseId, setSelectedWarehouseId] = React.useState('')
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear())
  const [selectedMonth, setSelectedMonth] = React.useState(new Date().getMonth() + 1)

  return (
    <Provider
      value={{
        selectedWarehouseId,
        setSelectedWarehouseId,
        selectedYear,
        setSelectedYear,
        selectedMonth,
        setSelectedMonth,
      }}
    >
      {children}
    </Provider>
  )
}
