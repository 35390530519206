import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, breakpoints } from '@vega/styled'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import './index.css'

import store from 'app/store'
import { RootRouter } from 'navigation/RootRouter'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{ breakpoints }}>
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
