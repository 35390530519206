import React from 'react'
import { Button as ButtonBase } from '@vega/components'
import { styled, s } from '@vega/styled'
import { PersonalDetailsCard } from './PersonalDetailsCard'
import { ContactDetailsCard } from './ContactDetailsCard'
import { UpdatePasswordCard } from './UpdatePasswordCard'
import { APP_URLS } from '@neo/constants'

const Container = styled.div(
  s('flex flex-column bg-green-50 h-full w-full py-12 pl-1/4 pr-24', {
    overflowY: 'auto',
  })
)

const Header = styled.div(s('flex items-center justify-between'))
const Title = styled.h2(s('m-0 font-semibold text-grey-700', { fontSize: 28 }))

const Subtitle = styled.span(s('text-grey-600 font-semibold inline-block mb-2'))

const ContentContainer = styled.div(s(''))

const LoggedOutBtn = styled((props) => (
  <ButtonBase containerStyle={s('px-4 py-3', { borderRadius: 6 })} {...props} />
))()

export const AccountSettings = () => {
  const onSignOut = () => {
    window.localStorage.removeItem('vegaUserCurrentRole')
    window.location.replace(`${APP_URLS.AUTH}sign-out`)
  }

  return (
    <Container>
      <Header style={s('mb-8')}>
        <Title>Account settings</Title>
        <LoggedOutBtn onClick={onSignOut}>Log out</LoggedOutBtn>
      </Header>

      <ContentContainer>
        <Subtitle>My personal details</Subtitle>
        <PersonalDetailsCard style={s('mb-8')} />

        <Subtitle>Contact details</Subtitle>
        <ContactDetailsCard style={s('mb-8')} />

        <Subtitle>Password</Subtitle>
        <UpdatePasswordCard />
      </ContentContainer>
    </Container>
  )
}
