import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { CardSection } from './cardSection'
import { Breakdowns } from './breakdowns'
import { LoanDetails } from './loanDetails'
import { MortgageMap } from './mortgageMap'
import { PortfolioManagementLineChart } from './portfolioManagementLineChart'
import { useWarehouseDashboard } from 'features/dashboard/warehouseDashboardContext'
import {
  selectDashboardAnalytics,
  fetchDashboardAnalytics,
  selectIsFetchingDashboardAnalytics,
} from 'modules/analytics'
import { selectIsFetchingWarehouses } from 'modules/warehouses'

const Container = styled.div(
  s('flex flex-column items-center w-full p-8', {
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)

const Row = styled.div(s('flex justify-between'))

export const WarehouseDashboard = () => {
  const dispatch = useDispatch()
  const { selectedWarehouseId, selectedYear, selectedMonth } = useWarehouseDashboard()

  const isFetchingDashboardAnalytics = useSelector(selectIsFetchingDashboardAnalytics)
  const isFetchingWarehouses = useSelector(selectIsFetchingWarehouses)
  const dashboardAnalytics = useSelector(selectDashboardAnalytics)

  const loanMetricsByMonth = dashboardAnalytics?.mortgage?.loan?.metricsByMonth
  const mortgageMetrics = dashboardAnalytics?.mortgage?.metrics
  const mortgageCountByLocation =
    dashboardAnalytics?.mortgage?.property?.mortgageCountByLocation

  const customerType = dashboardAnalytics?.mortgage?.customer?.type
  const propertyCategory = dashboardAnalytics?.mortgage?.property?.category

  const loanGrantedDataPoints = dashboardAnalytics?.dataPoint?.loanGranted
  // TODO: Uncomment out in later ticket after clarifying the meaning of WALVR
  // const weightedAverageLvrDataPoints = dashboardAnalytics?.dataPoint?.weightedAverageLvr
  const weightedAverageLvrDataPoints = []
  const averageLoanSizeDataPoints = dashboardAnalytics?.dataPoint?.averageLoanSize

  const isLoading = isFetchingDashboardAnalytics || isFetchingWarehouses

  useLayoutEffect(() => {
    dispatch(
      fetchDashboardAnalytics({
        year: selectedYear,
        month: selectedMonth,
        warehouseId: selectedWarehouseId,
      })
    )
  }, [dispatch, selectedMonth, selectedWarehouseId, selectedYear])

  return (
    <Container>
      <CardSection
        loanMetricsByMonth={loanMetricsByMonth}
        currentYear={selectedYear}
        currentMonth={selectedMonth}
        isLoading={isLoading}
        style={s('mb-8')}
      />

      <PortfolioManagementLineChart
        style={s('mb-8')}
        loanGrantedDataPoints={loanGrantedDataPoints}
        weightedAverageLvrDataPoints={weightedAverageLvrDataPoints}
        averageLoanSizeDataPoints={averageLoanSizeDataPoints}
        isLoading={isLoading}
      />

      <Row style={s('mb-8', { width: 1300 })}>
        <LoanDetails mortgageMetrics={mortgageMetrics} isLoading={isLoading} />
        <MortgageMap
          mortgageCountByLocation={mortgageCountByLocation}
          isLoading={isLoading}
        />
      </Row>

      <Breakdowns
        customerType={customerType}
        propertyCategory={propertyCategory}
        isLoading={isLoading}
      />
    </Container>
  )
}
