import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('flex'))
const LeftBar = styled.div(
  s('rounded-l-sm bg-green-600', { height: 12, marginRight: 1 })
)
const RightBar = styled.div(s('rounded-r-sm bg-grey-300', { height: 12 }))

const Bar = ({ value, ...props }) => {
  return (
    <Container {...props}>
      <LeftBar style={{ width: `${value}%` }} />
      <RightBar style={{ width: `${100 - value}%` }} />
    </Container>
  )
}

export { Bar }
