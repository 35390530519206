import React from 'react'

import { styled, s } from '@vega/styled'
import { prop } from '@solta/ramda-extra'
import { ReactComponent as Search } from '@vega/components/src/assets/images/search.svg'

const Container = styled.div(
  s(
    'flex relative items-center rounded-lg rounded-tr-none bg-white border-1 border-solid border-grey-200'
  ),
  prop('containerStyle')
)
const SearchBarContainer = styled.div(s('flex items-center w-full'))
const SearchIcon = styled(Search)(s('ml-4'))

const Input = styled.input(
  s('pl-2 pr-4 pt-3 pb-3 w-full border-0 bg-transparent text-grey-500 text-base', {
    '&:focus': {
      outline: 'none',
    },
  })
)

export const SearchField = ({
  onInputChange,
  placeholder,
  disabled,
  value,
  children,
  containerStyle,
  testId,
  ...inputProps
}) => {
  return (
    <Container containerStyle={containerStyle}>
      <SearchBarContainer>
        <SearchIcon height={20} width={20} />

        <Input
          placeholder={placeholder}
          onChange={onInputChange}
          value={value}
          type="text"
          disabled={disabled}
          data-test-id={testId}
          {...inputProps}
        />
      </SearchBarContainer>

      {children}
    </Container>
  )
}
