import React from 'react'
import { pluck } from '@solta/ramda-extra'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { SearchField } from './SearchField'
import { CreditScoreCardList } from './CreditScoreCardList'
import { styled, s } from '@vega/styled'
import { neoBank as defaultWarehouseData } from 'services/warehouse/mockData'
import { TableOfContent } from './TableOfContent'
import { useFluidTabs } from 'hooks/useFluidTabs'

const Container = styled.div(
  s('relative flex justify-start h-full', {
    overflowY: 'auto',
  })
)
const Title = styled.h2(s('text-grey-800 font-semibold mt-0', { fontSize: 28 }))
const Row = styled.div(s('flex items-center justify-between'))

const Aside = styled.aside(
  s('relative ml-8 w-1/4', {
    minWidth: 250,
    '@media (max-width: 850px)': {
      display: 'none',
    },
  })
)
const ContentContainer = styled.div(
  s('mb-24 px-4 pt-16', {
    width: 800,

    // implement scroll content padding bottom
    '&::after': s('w-full h-8 block', {
      content: '""',
    }),
  })
)
const LogoImg = styled.img(s('w-3 mr-4'))
const WarehouseName = styled.h3(s('text-grey-900 text-2xl'))

const TabsContainer = styled.div(
  s(
    'relative flex self-end justify-start border-0 border-b-2 border-solid border-grey-200'
  )
)
const Tab = styled.div(
  s('text-grey-500 text-sm pb-3 border-0 font-semibold'),
  ({ isSelected }) => ({
    cursor: 'pointer',
    color: isSelected && s('text-green-700').color,
  })
)
const TabBottomLine = styled.div(
  s('absolute bg-green-600 pin-bl-0', {
    width: 90,
    height: 2,
    bottom: 0,
    transition: 'left 200ms ease-in-out, width 200ms ease-in-out',
  })
)

export const DefaultWarehouse = () => {
  const contentContainerRef = React.useRef()
  const asideRef = React.useRef()
  const tabBottomLineRef = React.useRef()
  const criteriaNames = pluck('name', defaultWarehouseData.criteria)

  const { handleTabBottomLineMove, selectedTab } = useFluidTabs(
    ['creditCriteria'],
    tabBottomLineRef
  )

  const onTabClicked = (event, tabLabel) => {
    handleTabBottomLineMove(event, tabLabel)
  }

  React.useLayoutEffect(() => {
    // Allow the sticky aside to stick on the screen even users scroll
    asideRef.current.style.height = `${contentContainerRef.current.scrollHeight}px`
  }, [])

  return (
    <Container>
      <Aside ref={asideRef}>
        <TableOfContent tableListData={criteriaNames} />
      </Aside>

      <ContentContainer ref={contentContainerRef}>
        <Title>Configuration</Title>

        <Row>
          <Row>
            <LogoImg src={neoLogo} />
            <WarehouseName>Neo Prime</WarehouseName>
          </Row>

          <SearchField placeholder="Search any criteria" />
        </Row>

        <TabsContainer style={s('mb-6')}>
          <Tab
            onClick={(e) => onTabClicked(e, 'creditCriteria')}
            isSelected={selectedTab === 'creditCriteria'}
          >
            Credit Criteria
          </Tab>
          <TabBottomLine ref={tabBottomLineRef} />
        </TabsContainer>

        <CreditScoreCardList criteriaData={defaultWarehouseData.criteria} />
      </ContentContainer>
    </Container>
  )
}
